import React from "react";
import { IoEye, IoDocumentText, IoPulse } from "react-icons/io5";

import Image from "../../../components/image";
import { Container, Wrapper, Row, Box } from "../../../components/util";
import {
  CallToAction,
  FeaturePageHeader,
} from "../../../components/site";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

const Dashboard = (props) => (
  <Layout>
    <Seo
      title="Dashboard | Karmly Feature"
      description="Focus your operations team on tasks that need attention and get a high level overview of how your business is performing."
      pathname={props.location.pathname}
    />
    <FeaturePageHeader
      title="Dashboard"
      description="Focus your operations team on tasks that need attention. The Karmly dashboard is the central nervous system of your business and gives you a high level overview of how your business is performing."
      image="InvoxyFeature_Dashboard.png"
    />
    <Container>
      <Wrapper>
        <Row className="-textCenter" stackGap={40}>
          <Box size={33}>
            <h3>
              <IoEye />
              <br />
              At a Glance
            </h3>
            <p>
              See your key metrics, notifications, tasks and favourite reports
              on one screen.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoDocumentText />
              <br />
              Take Action
            </h3>
            <p>
              Quickly see what needs to be done next to stay on top of payroll
              and billing.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoPulse />
              <br />
              Monitor Performance
            </h3>
            <p>
              Check your revenue by client and a summary of this month’s key
              numbers.
            </p>
          </Box>
        </Row>
        <hr />
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="dashboardActionButtons.png"
              alt="Dashboard Information for Time & Billing"
            />
          </Box>
          <Box size={50}>
            <h3>Keep On Top Of Everything</h3>
            <p>
              See how many timesheets and approval requests you’re waiting on so
              you can chase them up and invoice sooner. Real time payroll and
              billing balances help you manage your cash flow. Click on any
              square to take action.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Know Your Numbers</h3>
            <p>
              See a summary of your gross margin and other key metrics for each
              month and ensure that you’re up to date with payroll and
              invoicing. Monitor your revenue per client over time.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Dashboard_PlacementMonthSummary.png"
              alt="Placement Monthly Summary"
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Dashboard_Notifications.png"
              alt="Dashboard Notifications"
            />
          </Box>
          <Box size={50}>
            <h3>Don’t Miss A Thing</h3>
            <p>
              Keep track of what’s going on in your account. Notifications
              include new clients, placements and users and any employee detail
              changes or onboarding responses requiring approval.
            </p>
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default Dashboard;
